import React, { useState, useEffect, useCallback } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { GetAlertwestTheme } from "@digitalpath-inc/alertwest-components-react/theme";

import MenuDrawer from "./menu/menu.component";
import FilterMenuDrawer from "./filterMenu/filterMenu.component";
import Navbar from "./navbar/navbar.component";

import { getAuthData, subscribeToAuthData, initializeAuthData } from "@alertwest/auth";

export default function Root(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);
    const [authState, setAuthState] = useState(getAuthData());

    useEffect(() => {
      const unsubscribe = subscribeToAuthData((newAuthState) => {
          setAuthState(newAuthState);
          console.log('New Auth State: ', newAuthState);
      });

      if (!authState?.isInitialized) {
        initializeAuthData();
      }
      
      return () => {
          unsubscribe();
      };
    }, []);

    const toggleMenu = () => {
        setFilterMenuOpen(false);
        setMenuOpen(current => !current);
    }

    const toggleFilterMenu = () => {
        setMenuOpen(false);
        setFilterMenuOpen(current => !current);
    }

    const theme = GetAlertwestTheme({});

    return (
        <ThemeProvider theme={theme}>
            <Navbar 
                handleMenuOpen={toggleMenu}
                handleFilterMenuOpen={toggleFilterMenu}
                userAttributes={authState?.userAttributes}
            />
            <MenuDrawer 
                open={menuOpen}
                handleDrawerOpen={toggleMenu}
            />
            <FilterMenuDrawer 
                open={filterMenuOpen}
                handleDrawerOpen={toggleFilterMenu}
            />
        </ThemeProvider>
    );
}
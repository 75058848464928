import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import SearchBar from './searchbar.component';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AwLogo from '../assets/images/aw_logo_white.svg';
import { signoutAuthSession } from '@alertwest/auth';
import { navigateToUrl } from 'single-spa';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1, // Ensure navbar is on top of the drawer
  position: 'fixed', // Navbar fixed at the top
  width: '100%', // Full width
  backgroundColor: '#18181B',
  backgroundImage: 'none',
  color: '#FDB01E',
}));

export default function Navbar({ handleMenuOpen, handleFilterMenuOpen, userAttributes }) {
  return (
    <Box 
    sx={{ 
        flexGrow: 1,
        height: '64px'
    }}>
        <AppBarStyled position="static">
            <Toolbar>
                <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                    onClick={handleMenuOpen}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="home"
                    sx={{ mr: 2 }}
                    onClick={ () => navigateToUrl('/') }
                    >
                    <HomeIcon />
                </IconButton>
                <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="home"
                    sx={{ mr: 2 }}
                    onClick={handleFilterMenuOpen}
                    >
                    <FilterAltIcon />
                </IconButton>
                <img src={AwLogo} alt="AlertWest Logo" style={{ height: '50px' }} />
                <Box sx={{ flexGrow: 1 }} />
                <SearchBar 
                    style={{ color: "#FFFFFF" }}
                />
                {!userAttributes && (
                    <Button
                        color="inherit"
                        onClick={ () => navigateToUrl('/login') }
                        size="medium"
                        edge="start"
                        sx={{ ml: 2 }}
                    >
                        Login
                    </Button>
                )}
                {userAttributes && (
                    <>
                        <Avatar sx={{ ml: 2 }}/>
                        <IconButton
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="home"
                            sx={{ ml: 2 }}
                            onClick={signoutAuthSession}
                        >
                            <LogoutIcon />
                        </IconButton>
                    </>
                )}
            </Toolbar>
        </AppBarStyled>
    </Box>
  );
}

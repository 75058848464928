import React, { useState } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { 
  Drawer, Box, Typography, Divider, TextField, Switch, 
  FormControlLabel, Select, MenuItem, Slider, IconButton,
  Modal, Checkbox, Button, InputAdornment
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFA500',
    },
    background: {
      paper: '#1A1816',
      default: '#2C2C2C',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
          backgroundColor: '#1A1816',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          backdropFilter: 'none',
        },
      },
    },
  },
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    marginTop: '64px',
    height: `calc(100% - 64px)`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.white,
    width: 350,
    backgroundImage: 'none',
  }
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: theme.palette.background.default,
  border: `2px solid ${theme.palette.divider}`,
  boxShadow: 24,
  p: 4,
  color: theme.palette.common.white,
  backgroundImage: 'none',
}));


const FilterSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& .MuiTypography-root': {
    marginBottom: theme.spacing(1),
  },
}));

const PresetButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  borderRadius: 20,
  padding: theme.spacing(0.5, 2),
  margin: theme.spacing(0.5),
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.common.white,
  },
}));

export default function FilterMenuDrawer({ open, handleDrawerOpen }) {
  const [anomalies, setAnomalies] = useState([0, 100]);
  const [detections, setDetections] = useState([20, 40]);
  const [riskLevel, setRiskLevel] = useState(50);
  const [settingsOpen, setSettingsOpen] = useState(false);
  
  const handleSettingsOpen = () => setSettingsOpen(true);
  const handleSettingsClose = () => setSettingsOpen(false);

  return (
    <ThemeProvider theme={darkTheme}>
      <StyledDrawer open={open} onClose={() => handleDrawerOpen()}>
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Global Filters</Typography>
            <Box>
              <IconButton size="small" onClick={handleSettingsOpen}>
                <SettingsIcon />
              </IconButton>
              <IconButton size="small" onClick={() => handleDrawerOpen()}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <FilterSection>
            <Typography variant="subtitle1">Presets</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {['Preset name', 'Preset name', 'Preset name', 'Preset name', 'Preset name'].map((preset, index) => (
                <PresetButton key={index} endIcon={<ExpandMoreIcon />}>
                  {preset}
                </PresetButton>
              ))}
            </Box>
          </FilterSection>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search for filter or layer"
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <FilterSection>
            <Typography variant="subtitle1">Cameras</Typography>
            <FormControlLabel control={<StyledSwitch defaultChecked />} label="Online" />
            <FormControlLabel control={<StyledSwitch defaultChecked />} label="Offline" />
            <FormControlLabel control={<StyledSwitch defaultChecked />} label="Last moved" />
            
            <Typography variant="subtitle2" sx={{ mt: 2 }}>University partner cameras</Typography>
            <Select
              fullWidth
              displayEmpty
              value=""
            >
              <MenuItem value="">Select partner</MenuItem>
            </Select>
            
            <Typography variant="subtitle2" sx={{ mt: 2 }}>Camera type</Typography>
            <Select
              fullWidth
              displayEmpty
              value=""
            >
              <MenuItem value="">Choose type</MenuItem>
            </Select>
          </FilterSection>

          <Divider sx={{ my: 2 }} />

          <FilterSection>
            <Typography variant="subtitle1">AI</Typography>
            <Typography variant="subtitle2">Anomalies</Typography>
            <StyledSlider
              value={anomalies}
              onChange={(event, newValue) => setAnomalies(newValue)}
              valueLabelDisplay="auto"
            />
            <Typography variant="subtitle2">Detections</Typography>
            <StyledSlider
              value={detections}
              onChange={(event, newValue) => setDetections(newValue)}
              valueLabelDisplay="auto"
            />
          </FilterSection>

          <Divider sx={{ my: 2 }} />

          <FilterSection>
            <Typography variant="subtitle1">Risk</Typography>
            <Typography variant="subtitle2">Risk level</Typography>
            <StyledSlider
              value={riskLevel}
              onChange={(event, newValue) => setRiskLevel(newValue)}
              valueLabelDisplay="auto"
              marks={[
                { value: 0, label: 'No risk' },
                { value: 100, label: 'High risk' },
              ]}
            />
          </FilterSection>
        </Box>
      </StyledDrawer>

      <Modal
        open={settingsOpen}
        onClose={handleSettingsClose}
        aria-labelledby="filter-options-modal"
        aria-describedby="filter-options-description"
      >
        <ModalContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6">Filter options</Typography>
            <IconButton onClick={handleSettingsClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '30%' }}>
              <FormControlLabel control={<StyledSwitch defaultChecked />} label="Camera filters" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Show camera status" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Show university partners" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Show camera type" />
              <FormControlLabel control={<StyledSwitch defaultChecked />} label="AI filters" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Show anomalies" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Show detections" />
              <FormControlLabel control={<StyledSwitch defaultChecked />} label="Risk filters" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Show risk level" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Show historical risk level" />
            </Box>
            <Box sx={{ width: '30%' }}>
              <FormControlLabel control={<StyledSwitch defaultChecked />} label="Organization layers" />
              <TextField
                placeholder="Search by layer name"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                sx={{ mb: 1 }}
              />
              <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                {Array(10).fill('Org layer').map((layer, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox defaultChecked />}
                    label={`${layer} ${index + 1}`}
                  />
                ))}
              </Box>
            </Box>
            <Box sx={{ width: '30%' }}>
              <FormControlLabel control={<StyledSwitch defaultChecked />} label="Satellite layers" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="MODIS Thermal V1" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="VIIRS Thermal Hotspots" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="GOES-R Fire Detection" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Satellite Fire Detections" />
              <FormControlLabel control={<StyledSwitch defaultChecked />} label="Other layers" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Air Attack Aircraft" />
            </Box>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary">
              Save Preferences
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
}